import React from 'react';
import styled from "styled-components"
import GlobalFonts from "../font/fonts"
import pool_after_img from '../images/pool_after_img.png'

import ResponsiveAppBar from "../components/ResponsiveAppBar"
import FooterContainer from "../components/FooterContainer"
import NextButton from "../components/NextButton"
import Lightbox from "react-image-lightbox";

import LineChartComp from "../components/LineChartComp"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from 'chart.js';

import { Bar,Line } from 'react-chartjs-2';
// import faker from 'faker';
import 'chart.js/auto'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement
);

export const options = {
  plugins: {
   tooltip: {
     mode: 'index',
     intersect: false
     },
    title: {
      display: true,
      text: 'Your Return From Your Stay',
    },
  },
  responsive: true,
  scales: {

      x: {
        display: true,
        title: {
          display: false
        }
      },
      y: {
        display: true,
        title: {
          display: false,
          text: 'Value'
        },
        // suggestedMin: -10,
        // suggestedMax: 200
      },
  },
};


const PropertySpotlight = () => {
  var starting_array = [1, 1, 1, 1, 1];
  const [value, setValue] = React.useState(1);
  const [input, set_input] = React.useState("$1,000");
  const [total_investment, set_total_investment] = React.useState(0.0);
  const [portfolio_value, set_portfolio_value] = React.useState(0.0);  
  const [yr_cashflow, set_yr_cashflow] = React.useState(0.0);  
  const [array_top, set_array_top] = React.useState(starting_array);
  const [isOpen, setIsOpen] = React.useState(true);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  var new_array = []

  var imageArrayInJSON = "https://a0.muscache.com/im/pictures/20ce19a0-944d-4f51-9223-a8f2b118494b.jpg?im_w=1200,https://a0.muscache.com/im/pictures/fea51cfc-8ea3-4de8-8017-0e65862fe83b.jpg?im_w=1440,https://a0.muscache.com/im/pictures/5bd23554-e3d5-499f-81b2-fb8be5227cf6.jpg?im_w=1440,https://a0.muscache.com/im/pictures/cbe4ac0e-c969-41f3-b651-7f2a9fa34c1f.jpg?im_w=1440,https://a0.muscache.com/im/pictures/4c3a7dea-be83-4d8b-b5f3-b2b0a98063b9.jpg?im_w=1440,https://a0.muscache.com/im/pictures/ed9bc9bf-59f2-47e0-b9a7-d018410bf41a.jpg?im_w=1440,https://a0.muscache.com/im/pictures/9be01dbb-ec01-440d-8cfc-e04c3c9f4bc8.jpg?im_w=1440,https://a0.muscache.com/im/pictures/1ccd5513-5c73-4633-a5d9-295e764c3702.jpg?im_w=1440,https://a0.muscache.com/im/pictures/9b070f5c-ca27-4452-bff3-84c6678033fa.jpg?im_w=1440,https://a0.muscache.com/im/pictures/caf9642f-3489-4d27-9503-df70225e8915.jpg?im_w=1440,https://a0.muscache.com/im/pictures/c67c5650-96be-4e27-9d1b-217f14ef310f.jpg?im_w=1440"
  var images = imageArrayInJSON.split(",");

  var amount_earned = [
        500.2,
        0,
        1693.67,
        4175.81,
        8545.55,
        2530.74,
        1233.6,
        603.93,
        2390.56,
        2786.43,
        0,
        1185.02,
        2426.04]
    

  var line_chart_props = {
    "revenue": [
        1487.3,
        7728.42,
        9454.5,
        14589.77,
        15654.17,
        10876.26,
        9244.94,
        10733.88,
        12309.01,
        13918.14,
        8644.78,
        10963.91,
        11772.4
    ],
    "costs": [
        -987.1,
        -9304.31,
        -7760.83,
        -10413.96,
        -7108.62,
        -8470.82,
        -6826.85,
        -9056.56,
        -8687.55,
        -8783.99,
        -9148.2,
        -9778.89,
        -9346.36
    ],
    "amount_earned": [
        500.2,
        0,
        1693.67,
        4175.81,
        8545.55,
        2530.74,
        1233.6,
        603.93,
        2390.56,
        2786.43,
        0,
        1185.02,
        2426.04
    ],
    "payment_end_date": [
        "Aug 21",
        "Sep 21",
        "Oct 21",
        "Nov 21",
        "Dec 21",
        "Jan 22",
        "Feb 22",
        "Mar 22",
        "Apr 22",
        "May 22",
        "Jun 22",
        "Jul 22",
        "Aug 22"
    ]
}

    React.useEffect(() => {

    },[])

    const labels = ['1yr','2yr','3yr','Apr 21','May 21','Jun 21','Jul 21','Aug 21','Jan 21','Feb 21','Mar 21','Apr 21','May 21','Jun 21','Jul 21','Aug 21'];

    // const array_top = [62, 59, 80, 81, 56];

    const onChangeHandler = event => {
       setValue(event.target.value);
       
       // console.log("value",input)       
       setUpGraph(parseFloat(value))
    };

    function numberWithCommas(x) {
      // if (x == NaN){return "0"} 
      x = x.toFixed(0)

      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    function prettyString(x) {
      // setValue(x)
      // var data = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

      return data
    }

    function setUpGraph(value){

      var new_array = [];
      var new_total_investment = 0.0
      
       for (var i = 0; i < amount_earned.length; i++) {


          new_total_investment = value + new_total_investment

          console.log(value)
          console.log(new_total_investment)
      }

      set_portfolio_value(42)

      set_total_investment(new_total_investment)

      set_yr_cashflow(new_total_investment * 0.18)

      // set_array_top(new_array)
      // console.log("new_array",new_array)
    }

    const data = {
      labels,
      datasets: [
           {
          label: 'Equity',
          // backgroundColor: 'rgba(75,192,192,1)',
          data: array_top,
          // backgroundColor: ['#131e3a', '#19284d', '#1f3260'],
          // hoverBackgroundColor: ['#131e3a', '#19284d', '#1f3260'],
          // backgroundColor: "blue",
          borderWidth: 5,
          hoverBackgroundColor: "rgba(102, 75, 218,0.4)",
          fill: {
            target: "origin", // Set the fill options
            above: "rgba(102, 75, 218, 0.3)"
          }
        },
        {
          label: 'Rental Income',
          backgroundColor: 'rgba(75,192,192,1)',
          data: [65, 59, 80, 81, 56]
        }
      ],
    };

  
    return (
    <>
    <GlobalFonts />
      <ResponsiveAppBar />
      <Background>
        <HowItWorks>PROPERTY SPOTLIGHT</HowItWorks>
        <HowItWorks2>Miami, Fl</HowItWorks2>
      </Background>
      <Hero>
        <Hero2>
          <MainImg src={pool_after_img} onClick={()=> setIsOpen(true)}></MainImg>
          {/*{isOpen && (
        <Lightbox style={{zPosition:10}}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={
            images[
              (photoIndex + images.length - 1) % images.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}*/}
          <StandardHeader>SOLD OUT</StandardHeader>
          <StandardText>Miami, Florida is one of the hottest markets in the US in 2022. Below you can see what you would have made if you invested in this Cloud Castle.   </StandardText>
          <CTAButton onClick={()=>setUpGraph(97)} style={{background:'grey'}}> Basic Package - Invest $97/Month</CTAButton>
          {/*<SubText>Invest $97/Month, Build Your Portfolio Throughout the Country, Cancel Anytime</SubText>*/}
          <CTAButton onClick={()=>setUpGraph(247)} style={{background:'#8bbef3'}}>Pro Package - Invest $247/Month</CTAButton>
          {/*<SubText>Invest $97/Month, Build Your Portfolio Throughout the Country, Cancel Anytime</SubText>*/}
          <CTAButton onClick={()=>setUpGraph(997)} style={{}}>Luxury Package - $997/Month</CTAButton>
          <CTAButton onClick={()=>setUpGraph(5000)} style={{background:'black'}}>Alpha Package - $5,000/Month</CTAButton>
          {/*<SubText>Invest $97/Month, Build Your Portfolio Throughout the Country, Cancel Anytime</SubText>*/}
{/*          <StandardText> - OR - </StandardText>
        <SubText>Choose your investment amount:</SubText>*/}
{/*        <GoalInput
           type="numbers"
           name="name"
           onChange={onChangeHandler}
           value={value}
        />       */} 
        </Hero2>
        <Hero1>
          <Hero5>
            <Hero4>          
              <StandardHeader>Total Invested</StandardHeader>
              <StandardHeader2>${numberWithCommas(total_investment)}</StandardHeader2>
            </Hero4>
            <Hero4>
              <StandardHeader>Property Appreciation</StandardHeader>
              <StandardHeader2>{numberWithCommas(portfolio_value)}%</StandardHeader2>
            </Hero4>
            <Hero4>
              <StandardHeader>Cash Flow</StandardHeader>
              <StandardHeader2>${numberWithCommas(yr_cashflow)}/ Year</StandardHeader2>
            </Hero4>
           </Hero5>
          <LineChartComp props={line_chart_props}/>

         {/* <Line options={options} data={data} plugins = {
          [{
            afterDraw: chart => {
              if (chart.tooltip?._active?.length) {
                let x = chart.tooltip._active[0].element.x;
                let yAxis = chart.scales.y;
                let ctx = chart.ctx;
                ctx.save();
                ctx.beginPath();
                ctx.setLineDash([5, 5]);
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'rgba(102, 75, 218, 0.4)';
                ctx.stroke();
                ctx.restore(); 
              }
            }
          }]
        }/>*/}
        
        </Hero1>
      </Hero>
      <FooterContainer />
      <NextButton
      title="Own A Cloud Castle"
      link1="/setup-a-meeting"
      link2="/webinar"
      btitle="I'm Ready To Talk"
      btitle1="Watch Our Webinar"
      // detail="Learn more"
      />
    </>
    );
  
}

export default PropertySpotlight


const Background = styled.div`
  width:100%;
  overflow-x: hidden;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
    background: radial-gradient(
    circle,
    rgb(139, 190, 243, 1) 0%,
    rgb(139, 190, 243, 0.6) 100%
  );
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
    height:200px;
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
    height:150px;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const Hero1 = styled.div`
  margin:  35px;
  padding:  35px;
  align-text:  right;
  width:70%;
  border-radius:33px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }
  @media only screen and (max-width: 1399px) and (min-width: 745px) {
    // width:60%;
    // height:200%;
  }
  @media only screen and (max-width: 744px) and (min-width: 100px) {
    height:200%;
    width:100%;
    // margin-right: 125px;
    margin:  0px;
    padding:  0px;
  }
`

const Hero2 = styled.div`
  margin:  15px;
  align-text:  left;
  width:20%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 2000px) and (min-width: 1000px) {
  }
  @media only screen and (max-width: 999px) and (min-width: 745px) {
    // width:40%;
        width:90%;
    flex-direction: column-reverse;
  }
  @media only screen and (max-width: 744px) and (min-width: 100px) {
    width:90%;
    flex-direction: column-reverse;
  }
`

const GoalInput = styled.input`
border-radius: 20px;
`

const MainImg = styled.img`
  border-radius: 20px;
  max-width:100%;
  // background:green;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`;


const Hero = styled.div`
  position:relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  
  overflow:auto;
  alignItems: stretch;
  height:100%;

  &:hover {
    // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
      // top: -15px;      
      // background: white;
      // box-shadow: 0px ;

  }

  @media only screen and (max-width: 2000px) and (min-width: 1000px) {
    align-items:center;
  }
  @media only screen and (max-width: 999px) and (min-width: 745px) {
    flex-direction: column-reverse;
  }
  @media only screen and (max-width: 744px) and (min-width: 100px) {
    flex-direction: column-reverse;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

`

const Hero4 = styled.div`
  margin:  15px;
  align-text:  center;
  display: flex;
  flex-direction: column;
  `

const Hero5 = styled.div`
  // margin:  15px;
  // align-text:  center;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  `

const HowItWorks = styled.h2`
  position: relative;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 65.7269px;
  line-height: 40px;
  /* identical to box height */
  letter-spacing: 1.56101px;
  text-transform: uppercase;

  color: white;

  // opacity: 0.04;

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
    margin-top:70px;
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
    margin-top:70px;   
    font-size: 35.7269px;
    line-height: 50px;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    margin-top:40px;   
    font-size: 20.7269px;
    line-height: 40px;
  }
`
const HowItWorks2 = styled.h2`
  position: relative;
  // width: 358px;
  // height: 60px;
  left: auto;
  right: auto;
  // top: 50.63px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22.9714px;
  line-height: 45px;
  letter-spacing: 1.56101px;
  text-transform: uppercase;

  color: white;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
    font-size: 13.9714px;
    line-height: 20px;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    font-size: 17.9714px;
    line-height: 25px;
  }
`
const StandardText = styled.h2`
  position: relative;
  margin-top: 20px;
  bottom: auto;
  left: auto;
  right: auto;
  font-family: Poppins;
  font-style: normal;
  // font-weight: 300;
  font-size: 12.9714px;
  // line-height: 5px;
  letter-spacing: 1.26101px;
  // text-transform: uppercase;

  color: #000000;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // left: 60.12px;
    // top: 0.63px;
    // padding-top:140px;
  }
`

const StandardHeader = styled.h2`
  position: relative;
  // margin-top: 20px;
  bottom: auto;
  left: auto;
  right: auto;
  text-align:center;
  font-family: Poppins;
  font-style: normal;
  // font-weight: 300;
  font-size: 22.9714px;
  // line-height: 5px;
  letter-spacing: 1.0101px;
  // text-transform: uppercase;
  color: #000000;
  @media only screen and (max-width: 2000px) and (min-width: 1000px) {
  }
  @media only screen and (max-width: 999px) and (min-width: 100px) {
    font-size: 12.9714px;
  }
`

const StandardHeader2 = styled.h2`
  position: relative;
  // margin-top: 20px;
  bottom: auto;
  left: auto;
  right: auto;
  text-align:center;
  font-family: Poppins;
  font-style: normal;
  // font-weight: 300;
  font-size: 26.9714px;
  // line-height: 5px;
  letter-spacing: 1.8101px;
  // text-transform: uppercase;
  color: #000000;
  @media only screen and (max-width: 2000px) and (min-width: 1000px) {
  }
  @media only screen and (max-width: 999px) and (min-width: 100px) {
    font-size: 12.9714px;
  }
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 10px;

  cursor: pointer;
  margin:10px;
  // margin-bottom:40px;
  width:80%;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12.1461px;
  line-height: 24px;

  color: #ffffff;

  background: #c73967;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
const SubText = styled.h2`
  position: relative;
  left: auto;
  right: auto;
  font-family: Poppins;
  // font-style: normal;
  // font-weight: 300;
  font-size: 12.9714px;
  // line-height: 5px;
  // letter-spacing: 1.26101px;
  // text-transform: uppercase;

  color: grey;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // left: 60.12px;
    // top: 0.63px;
    // padding-top:140px;
  }
`