import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar,Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  bezierCurve : true,
  plugins: {
    title: {
      display: true,
      text: 'Miami Cloud Castle Investment',
    },
  },
  responsive: true,
  scales: {
      x: {
        display: true,
        title: {
          display: true
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: ''
        },
        suggestedMin: -10,
        suggestedMax: 200
      },
  },
};

// const labels = ['January','February','February','February','February','February','February','February'];

// export const data = {
//   labels,
//   datasets: [
//        {
//       label: 'Rainfall',
//       backgroundColor: 'rgba(75,192,192,1)',
//       data: [62, 59, 80, 81, 56]
//     },
//            {
//       label: 'Firefall',
//       backgroundColor: 'rgba(75,192,192,1)',
//       data: [65, 59, 80, 81, 56]
//     }
//   ],
// };


// const BarChartComp = () => {
// export default class BarChartComp extends React.Component {
// function BarChartComp() {
export default function LineChartComp(props) {

    const labels = props["props"]["payment_end_date"]
    const revenue = props["props"]["revenue"]
    const costs = props["props"]["costs"]
    const amount_earned = props["props"]["amount_earned"]
    
    console.log(props)

    const data = {
    labels,
    datasets: [
      {
        label: 'Revenue',
        backgroundColor: 'rgba(58, 255, 181, 0.8)',
        data: revenue
      },
      {
        label: 'Costs',
        backgroundColor: 'rgba(255, 58, 115, 0.8)',
        data: costs
      },
      {
        label: 'Amount Earned',
        backgroundColor: 'rgba(58, 214, 255, 0.8)',
        data: amount_earned
      }
    ],
    };

  
    return (
      <div>
        <Line options={options} data={data} plugins = {
          [{
            afterDraw: chart => {
              if (chart.tooltip?._active?.length) {
                let x = chart.tooltip._active[0].element.x;
                let yAxis = chart.scales.y;
                let ctx = chart.ctx;
                ctx.save();
                ctx.beginPath();
                ctx.setLineDash([5, 5]);
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'rgba(102, 75, 218, 0.4)';
                ctx.stroke();
                ctx.restore(); 
              }
            }
          }]}/>

      </div>
    );
  
}